import React, { useEffect, useState } from "react"
import loadable from "@loadable/component"

const Item = loadable(() => import("./item"))

const Wrapper = ({ post }) => {
  const isSSR = typeof window === "undefined"
  const [article, setArticle] = useState(post)

  useEffect(() => {
    if (!isSSR && document.readyState === "complete") {
      setArticle({ ...post })
    }
  }, [post, isSSR])

  return <>{!isSSR && <Item post={article} />}</>
}

export default Wrapper
