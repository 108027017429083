import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"

const Breadcrumb = React.lazy(() => import("../components/breadcrumb"))

const TagPosts = ({ data }) => {
  const posts = data.wpTag.posts.nodes
  const tag = data.wpTag.name
  const isSSR = typeof window === "undefined"

  if (!posts.length) {
    return (
      <Layout>
        <p>
          No contents found. Add posts to your site and they'll appear here!
        </p>
      </Layout>
    )
  }

  return (
    <Layout>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Breadcrumb tax="关键词" term={tag} />
        </React.Suspense>
      )}
      <ol style={{ listStyle: `none` }}>
        {React.Children.toArray(
          posts.map(post => {
            return (
              <li>
                <Wrapper post={post} />
              </li>
            )
          })
        )}
      </ol>
    </Layout>
  )
}

export default TagPosts

export const pageQuery = graphql`
  query TagPostListById($id: String!) {
    wpTag(id: { eq: $id }) {
      name
      posts {
        nodes {
          id
          uri
          date(formatString: "MMMM DD, YYYY")
          title
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`
